/** @type {import('./types/harmony-config').IHarmonyConfig} */
const CONFIG = {
    title: '쇼핑이지',
    description: 'M포인트 100% 사용가능 쇼핑몰',
    domain: 'shoppingeasy',
    testEmail: 'super@freedgrouptech.com',
    testPassword: 'asdf123',
    locales: ['ko'],
    defaultLocale: 'ko',
    defaultCurrency: 'KRW',
    backgroundColor: '#ffffff',
    primaryTextColor: '#000000',
    primaryFont: 'noto',
    secondaryFont: 'poppins',
    marketplaceTemplate: ['tangible', 'intangible'],
    loginType: ['login'],
    isPwa: false,
    // Keep at bottom of file for pipeline build
    designTemplate: 'designA',
};

module.exports = CONFIG;
